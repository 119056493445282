import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box, Grid, IconButton } from "@mui/material";
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faTwitter, faInstagram, faTwitch } from '@fortawesome/free-brands-svg-icons';

const GradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(45deg, #00F2FE, #4FACFE)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: "3rem",
  fontWeight: "bold",
  [theme.breakpoints.down('sm')]: {
    fontSize: "2rem", // Adjust font size for mobile
  },
}));

const FormContainer = styled(Box)({
  marginTop: "2rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const SocialMediaContainer = styled(Grid)({
  marginTop: "3rem",
  display: "flex",
  justifyContent: "center",
  gap: "2rem",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "25px",
    "& fieldset": {
      borderColor: "#4FACFE",
    },
    "&:hover fieldset": {
      borderColor: "#00F2FE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00F2FE",
    },
  },
  input: {
    color: "#FFFFFF",
    padding: "10px 14px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%", // Make the input field fill the container
  },
}));

const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for the success message

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('access_key', 'ca854cfe-0226-4cd7-b4b6-ef181c02d323'); // Replace with your actual public access key
    formData.append('email', email);

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log("Waitlist email submitted successfully:", email);
        setSuccessMessage("Thank you for joining the waitlist!"); // Set success message
        setEmail(""); // Reset the email field
        setTimeout(() => {
          setSuccessMessage(""); // Reset the message after 5 seconds
        }, 5000);
      } else {
        console.error("Error submitting the form");
        setSuccessMessage("Something went wrong. Please try again."); // Error message
        setTimeout(() => {
          setSuccessMessage(""); // Reset the message after 5 seconds
        }, 5000);
      }
    } catch (error) {
      console.error("Network error:", error);
      setSuccessMessage("Network error. Please try again."); // Error message
      setTimeout(() => {
        setSuccessMessage(""); // Reset the message after 5 seconds
      }, 5000);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100vw",
        textAlign: "center",
        background: "#000000", // Fixed black background
        backgroundAttachment: "fixed", // Ensures it stays fixed on scroll
        color: "#FFFFFF",
        padding: "2rem",
      }}
    >

      <Box
        component="img"
        src="/esportium.png"
        alt="Esportium Logo"
        sx={{
          width: "200px",
          marginBottom: "1rem",
          "@media (max-width: 600px)": {
            width: "150px", // Adjust the logo size for mobile screens
          }
        }}
      />
      <GradientText variant="h1">Esportium</GradientText>
      <Typography variant="h5" sx={{ marginTop: "1rem" }}>
        Get ready for the ultimate competitive gaming experience, launching Winter 2024.
      </Typography>
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        Join the waitlist and be the first to experience Esportium!
      </Typography>

      {/* Show success message here */}
      {successMessage && (
        <Typography variant="h6" sx={{ marginTop: "1rem", color: "#00F2FE" }}>
          {successMessage}
        </Typography>
      )}

      <FormContainer>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column", // Stack vertically on mobile
            alignItems: "center", // Center the form on mobile
            gap: "10px",
            width: "100%", // Ensure the form takes full width on mobile
          }}
        >
          <StyledTextField
            variant="outlined"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              width: "300px",
              "@media (max-width: 600px)": {
                width: "100%", // Adjust input field width for mobile to take full width
              },
            }}
            InputLabelProps={{
              style: { color: '#FFFFFF' }
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              background: "linear-gradient(45deg, #00F2FE, #4FACFE)",
              color: "#FFFFFF",
              padding: "10px 20px",
              textTransform: "none",
              fontSize: "1rem",
              borderRadius: "25px",
              width: "300px", // Match button width with input field on larger screens
              "@media (max-width: 600px)": {
                width: "100%", // Full width on mobile screens
              },
            }}
          >
            Join Waitlist
          </Button>
        </form>
      </FormContainer>

      <Typography variant="body1" sx={{ marginTop: "2rem", fontStyle: "italic" }}>
        Follow us on social media for the latest updates and announcements!
      </Typography>

      <SocialMediaContainer container>
        <IconButton href="https://youtube.com/@esportium" target="_blank">
          <FontAwesomeIcon icon={faYoutube} style={{ color: "#FF0000", fontSize: 40 }} />
        </IconButton>
        <IconButton href="https://twitter.com/play_esportium" target="_blank">
          <FontAwesomeIcon icon={faTwitter} style={{ color: "#1DA1F2", fontSize: 40 }} />
        </IconButton>
        <IconButton href="https://instagram.com/esportium.gg" target="_blank">
          <FontAwesomeIcon icon={faInstagram} style={{ color: "#E4405F", fontSize: 40 }} />
        </IconButton>
        <IconButton href="https://twitch.tv/Esportium" target="_blank">
          <FontAwesomeIcon icon={faTwitch} style={{ color: "#9146FF", fontSize: 40 }} />
        </IconButton>
      </SocialMediaContainer>
    </Container>
  );
};

export default LandingPage;
